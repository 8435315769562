import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div css={{ padding: '1vw', background: 'rgb(224, 240, 255)' }}>
      {' '}
      <h1> Privacy Policy </h1>
      <h4> This policy is effective as of April 2, 2020. </h4>
      <h1> College Unfiltered Privacy Policy </h1>
      <h4>
        {' '}
        We built College Unfiltered to make it easy to share college-related
        information with fellow peers and prospective students. Our default
        privacy settings limit the use of your information.
      </h4>
      <h4>
        {' '}
        College Unfiltered’s Privacy Policy is designed to help you understand
        how we collect and use the personal information you decide to share, and
        help you make informed decisions when using College Unfiltered, located
        at www.CollegeUnfiltered.com and its directly associated domains
        (collectively, "College Unfiltered" or "Website").
      </h4>
      <h4>
        By using or accessing College Unfiltered, you are accepting the
        practices described in this Privacy Policy.
      </h4>
      <h1> The Information We Collect </h1>
      <h4>
        {' '}
        When you visit College Unfiltered, you provide us with two types of
        information: personal information you knowingly choose to disclose that
        is collected by us and Website use information collected by us as you
        interact with our Website.
      </h4>
      <h4>
        When you sign up with College Unfiltered, you provide us with certain
        personal information, such as your email address and any other personal
        or preference information that you provide to us.
      </h4>
      <h4>
        When you enter College Unfiltered, we collect your browser type and IP
        address. In addition, we store certain information from your browser
        using "cookies." A cookie is a piece of data stored on the user's
        computer tied to information about the user. We use session ID cookies
        to confirm that users are logged in. These cookies terminate once the
        user closes the browser.
      </h4>
      <h4>
        When you use College Unfiltered, you set up your personal account. We
        collect this information so that we can provide you the service and
        offer personalized features. In most cases, we retain it so that, for
        instance, you can return to view prior responses you have written.
      </h4>
      <h4>
        You post User Content on the Website at your own risk. Although we do
        not share your name or email address, we cannot control the actions of
        other Users with whom you may choose to share your responses and
        information. Therefore, we cannot and do not guarantee that User Content
        you post on the Site will not be viewed by unauthorized persons. We are
        not responsible for circumvention of any privacy settings or security
        measures contained on the Site. You understand and acknowledge that,
        even after removal, copies of User Content may remain viewable in cached
        and archived pages or if other Users have copied or stored your User
        Content.
      </h4>
      <h4>
        Any improper collection or misuse of information provided on
        CollegeUnfiltered.com is a violation of the College Unfiltered Terms of
        Use and should be reported to{' '}
        <a href="mailto:collegeunfiltereduva@gmail.com">
          collegeunfiltereduva@gmail.com
        </a>
        .
      </h4>
      <h4>
        By answering questions on CollegeUnfiltered.com, you consent to have any
        of your responses posted anonymously for public view, along with their
        associated date and time stamps and your provided school year.
      </h4>
      <h4>
        By using CollegeUnfiltered.com, you are consenting to have your personal
        data transferred to and processed in the United States.
      </h4>
      <h1>Children Under Age 13</h1>
      <h4>
        College Unfiltered does not knowingly collect or solicit personal
        information from anyone under the age of 13 or knowingly allow such
        persons to register. If you are under 13, please do not attempt to
        register for College Unfiltered or send any information about yourself
        to us, including your name or email address. No one under age 13 may
        provide any personal information to or on CollegeUnfiltered.com. In the
        event that we learn that we have collected personal information from a
        child under age 13 without verification of parental consent, we will
        delete that information as quickly as possible. If you believe that we
        might have any information from or about a child under 13, please
        contact us at{' '}
        <a href="mailto:collegeunfiltereduva@gmail.com">
          collegeunfiltereduva@gmail.com
        </a>
      </h4>
      <h1>Children Between the Ages of 13 and 18</h1>
      <h4>
        We recommend that minors over the age of 13 ask their parents for
        permission before sending any information about themselves to anyone
        over the Internet.
      </h4>
      <h1>Use of Information Obtained by College Unfiltered</h1>
      <h4>
        When you register with College Unfiltered, you create your own account.
        Your account information, as well as your name and email, are not
        displayed to other Users. We may occasionally use your name and email
        address to send you notifications regarding new services offered by
        College Unfiltered that we think you may find valuable.
      </h4>
      <h4>
        Account information is used by College Unfiltered primarily to verify
        user identity and be presented back to and edited by you when you access
        the service. College Unfiltered may send you service-related
        announcements from time to time through the general operation of the
        service.
      </h4>
      <h4>
        College Unfiltered may use information in your account without
        identifying you as an individual (i.e., without sharing your name, email
        address, and other personal identifying information) to third parties.
        We do this for purposes such as aggregating data about a college and
        personalizing advertisements and promotions so that we can provide you
        with College Unfiltered.
      </h4>
      <h1>Sharing Your Information with Third Parties</h1>
      <h4>
        College Unfiltered is centered around sharing information with fellow
        and prospective students while providing you with privacy settings that
        restrict other users from accessing your information. We do not provide
        contact information to third party marketers without your permission. We
        share your information with third parties only in limited circumstances
        where we believe such sharing is 1) reasonably necessary to offer the
        service, 2) legally required or, 3) permitted by you. For example:
      </h4>
      <h4>
        • We may provide information to service providers to help us bring you
        the services we offer. Specifically, we may use third parties to
        facilitate our business, such as to host the service at a hosting
        facility for servers, to send out email updates about College
        Unfiltered, to remove repetitive information from our user lists, or to
        provide search results or links (including sponsored links). In
        connection with these offerings and business operations, our service
        providers may have access to your personal information for use for a
        limited time in connection with these business activities. Where we
        utilize third parties for the processing of any personal information, we
        implement reasonable contractual and technical protections limiting the
        use of that information to College Unfiltered-specified purposes.
      </h4>
      <h4>
        • We may be required to disclose user information pursuant to lawful
        requests, such as subpoenas or court orders, or in compliance with
        applicable laws. We do not reveal information until we have a good faith
        belief that an information request by law enforcement or private
        litigants meets applicable legal standards. Additionally, we may share
        account or other information when we believe it is necessary to comply
        with law, to protect our interests or property, to prevent fraud or
        other illegal activity perpetrated through College Unfiltered service or
        using the College Unfiltered name, or to prevent imminent bodily harm.
        This may include sharing information with other companies, lawyers,
        agents or government agencies. We may offer stores or provide services
        jointly with other companies on College Unfiltered. You can tell when
        another company is involved in any store or service provided on College
        Unfiltered, and we may share customer information with that company in
        connection with your use of that store or service.
      </h4>
      <h4>
        • If the ownership of all or substantially all of the College Unfiltered
        business, or individual business units owned by College Unfiltered, were
        to change, your user information may be transferred to the new owner so
        the service can continue operations. In any such transfer of
        information, your user information would remain subject to the promises
        made in any pre-existing Privacy Policy.
      </h4>
      <h1>Links</h1>
      <h4>
        College Unfiltered may contain links to other websites. We are of course
        not responsible for the privacy practices of other websites. We
        encourage our users to be aware when they leave our site to read the
        privacy statements of each and every website that collects personally
        identifiable information. This Privacy Policy applies solely to
        information collected by College Unfiltered.
      </h4>
      <h1>Security</h1>
      <h4>
        College Unfiltered takes appropriate precautions to protect our users'
        information. Since email and instant messaging are not recognized as
        secure communications, we request that you not send private information
        to us by email or instant messaging services. If you have any questions
        about the security of College Unfiltered Website, please contact us at{' '}
        <a href="mailto:collegeunfiltereduva@gmail.com">
          collegeunfiltereduva@gmail.com
        </a>
        .
      </h4>
      <h1>Terms of Use, Notices and Revisions</h1>
      <h4>
        Your use of College Unfiltered, and any disputes arising from it, is
        subject to this Privacy Policy as well as our Terms of Use and all of
        its dispute resolution provisions including arbitration, limitation on
        damages and choice of law. We reserve the right to change our Privacy
        Policy and our Terms of Use at any time. Non-material changes and
        clarifications will take effect immediately, and material changes will
        take effect within 30 days of their posting on this site. If we make
        changes, we will post them and will indicate at the top of this page the
        policy's new effective date. If we make material changes to this policy,
        we will notify you here, by email, or through notice on our home page.
        We encourage you to refer to this policy on an ongoing basis so that you
        understand our current privacy policy. Unless stated otherwise, our
        current privacy policy applies to all information that we have about you
        and your account.
      </h4>
      <h1>Contacting the Website</h1>
      <h4>
        If you have any questions about this privacy policy, please contact us
        at{' '}
        <a href="mailto:collegeunfiltereduva@gmail.com">
          collegeunfiltereduva@gmail.com
        </a>
        .
      </h4>
    </div>
  );
}
