import styled from '@emotion/styled';

export const FormContainer = styled.form({
  display: 'flex',
  flexDirection: 'column',
});

export const LinkColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

export const LinkRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'flex-end',
});
