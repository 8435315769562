import React from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { Link, SEO } from '../../components';
import { SelectField, TextField, SubmitButton } from '../../components/form';
import { useSignup } from './utils';
import { useLocation } from '@reach/router';
import { FormContainer, LinkRow } from '../styles';

export default function Signup() {
  const defaultValues = useLocation().state || {};
  const formMethods = useForm({ defaultValues });
  const { handleSubmit, watch } = formMethods;
  const signup = useSignup();

  return (
    <>
      <SEO title="Signup" />
      <FormContext {...formMethods}>
        <FormContainer onSubmit={handleSubmit(signup)}>
          <TextField name="email" label="Email" />
          <TextField
            name="password"
            label="Password"
            type="password"
            placeholder="At least 6 characters"
          />
          <TextField
            name="confirmpassword"
            label="Confirm Password"
            type="password"
          />
          <SelectField
            name="year"
            label="Student / Parent"
            options={[
              { value: 'parent', label: 'Parent' },
              { value: '12th', label: '12th Grade / Incoming First Year' },
              { value: '11th', label: '11th Grade' },
              { value: '10th', label: '10th Grade' },
              { value: '9th', label: '9th Grade' },
              { value: 'middle', label: 'Middle School' },
            ]}
            placeholder="Please make a selection"
          />
          <SubmitButton
            css={{ margin: '1rem 0', fontWeight: 'bold' }}
            label="Sign Up"
          />
          <LinkRow>
            <Link
              to={`/app/auth/hs/login`}
              size="med"
              state={watch(['email', 'password'])}
              css={{ marginTop: '1.5rem' }}
            >
              {' < Back'}
            </Link>
            <Link
              to={`/app/auth/hs/login`}
              size="small"
              state={watch(['email', 'password'])}
              css={{ textDecoration: 'underline', textAlign: 'right' }}
            >
              Already have an account? Log in
            </Link>
          </LinkRow>
        </FormContainer>
      </FormContext>
    </>
  );
}
