export const ERROR_REQUIRED_FIELDS = 'Please enter all required fields.';

export const MESSAGE_CREATING_ACCOUNT = 'Creating your account...';
export const ERROR_WEAK_PASSWORD =
  'Please choose a password of 6 characters or more.';
export const ERROR_EMAIL_IN_USE =
  'This email address is already in use by another account.';
export const ERROR_PASSWORD_MATCH = 'Please make sure your passwords match.';
export const ERROR_SIGNUP_GENERIC =
  'There was an error creating your account. Please email collegeunfiltereduva@gmail.com for help.';

export const ERROR_INVALID_PASSWORD =
  'The password entered is incorrect. Please try again or click "Forgot password?"';
export const ERROR_LOGIN_GENERIC =
  'There was an error logging you in. Please email collegeunfiltereduva@gmail.com for help.';

export const MESSAGE_RECOVERY_EMAIL_SENT = 'A recovery email has been sent.';
export const ERROR_FORGOT_PASSWORD_GENERIC =
  'There was an error resetting your password. Please email collegeunfiltereduva@gmail.com for help.';
