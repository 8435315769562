import React from 'react';
import ShortAnswer from './ShortAnswer';
import SingleSelectShortAnswer from './SingleSelectShortAnswer';
import MultiSelectShortAnswer from './MultiSelectShortAnswer';

export default function Question({ type, ...rest }) {
  switch (type) {
    case 'short_answer':
      return <ShortAnswer {...rest} />;
    case 'single_select_and_short_answer':
      return <SingleSelectShortAnswer {...rest} />;
    case 'multi_select_and_short_answer':
      return <MultiSelectShortAnswer {...rest} />;
    default:
      return <p>Invalid type of {type}</p>;
  }
}
