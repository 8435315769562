import React from 'react';
import { Router } from '@reach/router';
import Layout from './layout';
import CollegeCategory from './category/college';
import HSCategory from './category/hs';
import { navigate } from 'gatsby';
import { useUser } from '../providers';
import Home from './home';
import Feedback from './input/Feedback';
import SubmitQuestion from './input/SubmitQuestion';

export default function ContentRouter({ college }) {
  const { user, role } = useUser();

  // Waiting for data to be loaded before checking
  if (user === undefined || role === undefined) return null;
  if (user === null) {
    navigate('/app/auth');
    return null;
  }
  if (!user.emailVerified) {
    navigate('/app/auth/verify');
    return null;
  }

  return (
    <Layout college={college}>
      {role === college ? <CollegeRouter /> : <HSRouter />}
    </Layout>
  );
}

function CollegeRouter() {
  return (
    <Router css={{ height: '100%' }}>
      <Home path="/" />
      <CollegeCategory path=":category" />
      <Feedback path="/feedback" />
      <SubmitQuestion path="/submit" />
    </Router>
  );
}

function HSRouter() {
  return (
    <Router css={{ height: '100%' }}>
      <Home path="/" />
      <HSCategory path=":category" />
      <Feedback path="/feedback" />
      <SubmitQuestion path="/submit" />
    </Router>
  );
}
