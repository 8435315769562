import React, { useContext } from 'react';
import { categories } from '../categories';
import { Box, Tab, Select, ResponsiveContext } from 'grommet';
import { navigate } from 'gatsby';

export default function Layout({ category, children }) {
  const size = useContext(ResponsiveContext);
  const labels = Object.keys(categories).map((key) => ({
    value: key,
    ...categories[key],
  }));

  return (
    <Box width="85rem" css={{ height: '100%' }}>
      <Box
        background="#E3EFFE"
        pad="xsmall"
        direction="row"
        justify="around"
        css={{ flexShrink: 0 }}
      >
        {size === 'small' ? (
          <Select
            plain
            options={labels}
            labelKey="label"
            valueKey={{ key: 'value', reduce: true }}
            value={category}
            onChange={({ value }) => navigate(`/app/uva/${value}`)}
          />
        ) : (
          labels.map((label) => (
            <Tab
              active={category === label.value}
              key={label.value}
              title={label.label}
              onActivate={() => navigate(`/app/uva/${label.value}`)}
            />
          ))
        )}
      </Box>
      {/* This sucks, I'm sorry :( */}
      <Box background="#3863B5">
        <div css={{ overflowY: 'auto' }}>
          <Box pad="small">{children}</Box>
        </div>
      </Box>
    </Box>
  );
}
