import React from 'react';
import { Box } from 'grommet';
import Logo from '../../components/Logo';

export default function Layout({ children }) {
  return (
    <Box
      width="30rem"
      background="brand"
      round="xsmall"
      css={{
        marginTop: '15vh',
        marginBottom: 'auto',
        padding: '1.5rem',
      }}
    >
      <Logo css={{ marginBottom: '1rem' }} />
      {children}
    </Box>
  );
}
