import React from 'react';
import { Text, Button } from 'grommet';
import Layout from './Layout';

export default function SubmitQuestion() {
  return (
    <Layout>
      <Text
        size="medium"
        textAlign="center"
        weight="bold"
        css={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        <div>Have more questions you'd like answered?</div>
        Click the button below to submit questions to be answered soon!
      </Text>

      <a
        href="https://forms.gle/mii777qNPFWTXCFg8"
        target="_blank"
        rel="noreferrer"
        css={{ alignSelf: 'center' }}
      >
        <Button
          primary
          css={{ marginTop: '1rem', fontWeight: 'bold' }}
          label="Submit Questions"
        />
      </a>
    </Layout>
  );
}
