import React, { useState, useEffect, useMemo } from 'react';
import { Spinner, SEO } from '../../../components';
import Layout from '../Layout';
import { useUser } from '../../../providers';
import { getQuestionData, getQuestionsAnswersByUID } from '../utils';
import Question from './Question';
import { categories } from '../../categories';

export default function CollegeCategory({ category }) {
  const [questions, setQuestions] = useState();
  const { user } = useUser();

  useEffect(() => {
    // Clearing data on category change
    setQuestions();

    getQuestionData({ category })
      .then((questions) => {
        return getQuestionsAnswersByUID({ questions, uid: user.uid });
      })
      .then((questions) => {
        setQuestions(questions);
      });
  }, [category, user]);

  const sortedQuestions = useMemo(() => {
    if (!questions) return null;
    return [...questions].sort((a, b) => {
      if (!!a.answer !== !!b.answer) {
        return a.answer ? 1 : -1;
      }
      return 0;
    });
  }, [questions]);

  return (
    <Layout category={category}>
      <SEO title={categories[category].label} />
      {!questions ? (
        <Spinner />
      ) : (
        sortedQuestions.map((question) => (
          <Question
            key={question.id}
            {...question}
            css={{ marginBottom: '2rem' }}
          />
        ))
      )}
    </Layout>
  );
}
