import React from 'react';
import { Router } from '@reach/router';
import AuthRouter from '../auth/Router';
import ContentRouter from '../content/Router';
import PrivacyPolicy from '../content/PrivacyPolicy';

export default function App() {
  return (
    <Router basepath="/app">
      <AuthRouter path="auth/*" />
      <ContentRouter path=":college/*" />
      <PrivacyPolicy path="/privacy" />
    </Router>
  );
}
