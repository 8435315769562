import React from 'react';
import AcademicsImage from './home/AcademicsImage';
import CareerImage from './home/CareerImage';
import DiningImage from './home/DiningImage';
import HousingImage from './home/HousingImage';
import SocialImage from './home/SocialImage';
import SportsImage from './home/SportsImage';
import MajorsImage from './home/MajorsImage';

export const categories = {
  academics: {
    label: 'Academics',
    image: ({ children, ...props }) => (
      <AcademicsImage {...props}>{children}</AcademicsImage>
    ),
  },
  career: {
    label: 'Career',
    image: ({ children, ...props }) => (
      <CareerImage {...props}>{children}</CareerImage>
    ),
  },

  housing: {
    label: 'Housing',
    image: ({ children, ...props }) => (
      <HousingImage {...props}>{children}</HousingImage>
    ),
  },
  social: {
    label: 'Social',
    image: ({ children, ...props }) => (
      <SocialImage {...props}>{children}</SocialImage>
    ),
  },
  dining: {
    label: 'Dining',
    image: ({ children, ...props }) => (
      <DiningImage {...props}>{children}</DiningImage>
    ),
  },
  sports: {
    label: 'Sports',
    image: ({ children, ...props }) => (
      <SportsImage {...props}>{children}</SportsImage>
    ),
  },
  majors: {
    label: 'Majors',
    image: ({ children, ...props }) => (
      <MajorsImage {...props}>{children}</MajorsImage>
    ),
  },
};
