import React from 'react';
import { Router } from '@reach/router';
import Layout from './Layout';
import AuthPicker from './AuthPicker';
import CollegeLogin from './college/Login';
import CollegeSignup from './college/Signup';
import CollegeForgotPassword from './college/ForgotPassword';
import HSLogin from './hs/Login';
import HSSignup from './hs/Signup';
import HSForgotPassword from './hs/ForgotPassword';
import EmailVerification from './EmailVerification';
import ActionCode from './actionCode';

export default function AuthRouter() {
  return (
    <Layout>
      <Router>
        <AuthPicker path="/" />
        <CollegeRouter path=":college/*" />
        <HSRouter path="hs/*" />
        <EmailVerification path="verify" />
        <ActionCode path="action" />
      </Router>
    </Layout>
  );
}

const CollegeRouter = () => (
  <Router>
    <CollegeLogin path="login" />
    <CollegeSignup path="signup" />
    <CollegeForgotPassword path="forgot-password" />
  </Router>
);

const HSRouter = () => (
  <Router>
    <HSLogin path="login" />
    <HSSignup path="signup" />
    <HSForgotPassword path="forgot-password" />
  </Router>
);
