import { useSnackbar } from '../../providers';
import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import {
  ERROR_REQUIRED_FIELDS,
  ERROR_PASSWORD_MATCH,
  ERROR_WEAK_PASSWORD,
  ERROR_EMAIL_IN_USE,
  ERROR_SIGNUP_GENERIC,
  MESSAGE_CREATING_ACCOUNT,
} from '../constants';

// Set of helper functions for auth forms
export const useSignup = () => {
  const setNotification = useSnackbar();

  return async ({ email, password, confirmpassword, year }) => {
    try {
      if (!(email && password && year?.value)) {
        setNotification(ERROR_REQUIRED_FIELDS);
        return;
      }

      if (password !== confirmpassword) {
        setNotification(ERROR_PASSWORD_MATCH);
        return;
      }

      setNotification(MESSAGE_CREATING_ACCOUNT);
      let user;
      try {
        const response = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);
        user = response.user;
      } catch (e) {
        switch (e.code) {
          case 'auth/weak-password':
            setNotification(ERROR_WEAK_PASSWORD);
            return;
          case 'auth/email-already-in-use':
            setNotification(ERROR_EMAIL_IN_USE);
            return;
          default:
            throw e;
        }
      }

      await Promise.all([
        user.sendEmailVerification(),
        firebase.firestore().collection('users').doc(user.uid).set({
          year: year?.value,
          registeredAt: firebase.firestore.Timestamp.now(),
        }),
      ]);

      navigate('/app/verify');
    } catch (e) {
      console.error(e);
      setNotification(ERROR_SIGNUP_GENERIC);
    }
  };
};
