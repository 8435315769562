import { useSnackbar } from '../providers';
import firebase from 'firebase/app';
import { navigate } from 'gatsby';

import {
  ERROR_REQUIRED_FIELDS,
  ERROR_INVALID_PASSWORD,
  ERROR_LOGIN_GENERIC,
  MESSAGE_RECOVERY_EMAIL_SENT,
  ERROR_FORGOT_PASSWORD_GENERIC,
} from './constants';

// Set of helper functions for auth forms
export const useLogin = () => {
  const setNotification = useSnackbar();

  // Falls back to computing ID if email is not passed
  return async ({ email, computingID, password, rememberMe }) => {
    try {
      if (!((email || computingID) && password)) {
        setNotification(ERROR_REQUIRED_FIELDS);
        return;
      }

      await firebase
        .auth()
        .setPersistence(
          rememberMe
            ? firebase.auth.Auth.Persistence.LOCAL
            : firebase.auth.Auth.Persistence.SESSION
        );

      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(
            email || `${computingID}@virginia.edu`,
            password
          );
      } catch (e) {
        if (e.code === 'auth/wrong-password') {
          setNotification(ERROR_INVALID_PASSWORD);
          return;
        } else throw e;
      }

      navigate('/app/uva');
    } catch (e) {
      console.error(e);
      setNotification(ERROR_LOGIN_GENERIC);
    }
  };
};

export const useForgotPassword = () => {
  const setNotification = useSnackbar();

  // Falls back to computing ID if email is not passed
  return async ({ email, computingID }) => {
    try {
      if (!(email || computingID)) {
        setNotification(ERROR_REQUIRED_FIELDS);
        return;
      }

      await firebase
        .auth()
        .sendPasswordResetEmail(email || `${computingID}@virginia.edu`);

      setNotification(MESSAGE_RECOVERY_EMAIL_SENT);
    } catch (e) {
      console.error(e);
      setNotification(ERROR_FORGOT_PASSWORD_GENERIC);
    }
  };
};
