import React from 'react';
import { Button } from 'grommet';
import { useFormContext } from 'react-hook-form';

export default function SubmitButton(props) {
  const { formState } = useFormContext();
  return (
    <Button
      type="submit"
      disabled={formState.isSubmitting}
      primary
      {...props}
    />
  );
}
