import React, { useContext } from 'react';
import { Box, Text, Anchor, ResponsiveContext } from 'grommet';
import { Instagram as InstagramIcon } from '@styled-icons/boxicons-logos';
import { Facebook as FacebookIcon } from '@styled-icons/entypo-social';

const Footer = () => {
  const size = useContext(ResponsiveContext);
  return size === 'small' ? <MobileFooter /> : <DesktopFooter />;
};

const MobileFooter = () => (
  <Box
    background="#202020"
    align="center"
    // Used to fix a bug with padding not being respected by children
    css={{ minHeight: 'auto' }}
    pad="small"
  >
    <Box direction="row" align="center" gap="small">
      <Text size="small">Copyright © 2020</Text>
      <Anchor
        icon={<InstagramIcon size={25} color="white" />}
        href="https://www.instagram.com/collegeunfiltereduva/"
        target="_blank"
        css={{ padding: '0.1rem', display: 'flex' }}
      />
      <Anchor
        icon={<FacebookIcon size={20} color="white" />}
        href="https://www.facebook.com/CollegeUnfiltered"
        target="_blank"
        css={{ padding: '0.1rem', display: 'flex' }}
      />
      <Text size="small">
        <Anchor
          href="/app/privacy"
          target="_blank"
          css={{ color: 'white', textDecoration: 'underline' }}
        >
          Privacy Policy
        </Anchor>
      </Text>
    </Box>
    <Text size="small">Not affiliated with the University of Virginia</Text>
  </Box>
);

const DesktopFooter = () => (
  <Box
    background="#202020"
    align="center"
    // Used to fix a bug with padding not being respected by children
    css={{ minHeight: 'auto' }}
  >
    <Box direction="row" align="center">
      <Text size="small" css={{ marginTop: 4, marginBottom: 4 }}>
        <span css={{ padding: '0.3rem' }}>Copyright © 2020</span> |{' '}
        <Anchor
          href="/app/privacy"
          target="_blank"
          css={{
            color: 'white',
            padding: '0.3rem',
            textDecoration: 'underline',
          }}
        >
          Privacy Policy
        </Anchor>{' '}
        |{' '}
        <Anchor
          icon={<InstagramIcon size={25} color="white" />}
          href="https://www.instagram.com/collegeunfiltereduva/"
          target="_blank"
          css={{ padding: '0.3rem' }}
        />
        <Anchor
          icon={<FacebookIcon size={21} color="white" />}
          href="https://www.facebook.com/CollegeUnfiltered"
          target="_blank"
          css={{ padding: '0.3rem' }}
        />
        <span css={{ paddingLeft: '0.2rem' }}> </span>|{' '}
        <span css={{ padding: '0.3rem' }}>
          Not affiliated with the University of Virginia
        </span>
      </Text>
    </Box>
  </Box>
);

export default Footer;
