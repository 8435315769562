import React from 'react';
import { Box } from 'grommet';
import { SEO } from '../../components';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import Header from './Header';
import Footer from './Footer';

const BackgroundSection = ({ children, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "carter-sunset.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <BackgroundImage
      fluid={data.desktop.childImageSharp.fluid}
      css={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        backgroundPosition: 'top center',
        backgroundSize: 'cover',
      }}
      {...props}
    >
      {children}
    </BackgroundImage>
  );
};

const Layout = ({ children, college }) => (
  <Box height={`${window.innerHeight}px`}>
    <SEO title="Home" />
    <Header college={college} />
    <BackgroundSection>
      <Box css={{ margin: '0.5rem', overflowY: 'auto', overflowX: 'hidden' }}>
        {children}
      </Box>
    </BackgroundSection>
    <Footer />
  </Box>
);

export default Layout;
