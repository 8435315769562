export const colleges = {
  uva: {
    name: 'UVA',
  },
  jmu: {
    name: 'JMU',
  },
  vtech: {
    name: 'Virginia Tech',
  },
  vcu: {
    name: 'VCU',
  },
};
