import React, { useContext } from 'react';
import { Box, Text, ResponsiveContext } from 'grommet';
import { format as formatDate } from 'date-fns';
import { BarChart } from '../../../components';

const getChartData = ({ type, answers }) => {
  switch (type) {
    case 'single_select_and_short_answer': {
      const data = answers.reduce((acc, cur) => {
        if (!acc[cur.selection]) acc[cur.selection] = 0;
        acc[cur.selection]++;
        return acc;
      }, {});
      for (const option in data) {
        data[option] = (data[option] / answers.length) * 100;
      }
      return data;
    }
    case 'multi_select_and_short_answer': {
      const data = answers.reduce((acc, cur) => {
        for (const selection of cur.selections) {
          if (!acc[selection]) acc[selection] = 0;
          acc[selection]++;
        }
        return acc;
      }, {});
      for (const option in data) {
        data[option] = (data[option] / answers.length) * 100;
      }
      return data;
    }
    default: {
      return null;
    }
  }
};

const getAnswerString = ({ type, answer }) => {
  switch (type) {
    case 'short_answer': {
      return answer.text;
    }
    case 'single_select_and_short_answer': {
      let str = answer.selection;
      if (answer.text) {
        str += `: ${answer.text}`;
      }
      return str;
    }
    case 'multi_select_and_short_answer': {
      let str = answer.selections.join(', ');
      if (answer.text) {
        str += `: ${answer.text}`;
      }
      return str;
    }
    default: {
      throw new Error('Invalid question type');
    }
  }
};

const getMetaString = ({ date, year }) => {
  const formattedDate =
    date && formatDate(new Date(date._seconds * 1000), 'MMM d, yyyy');
  const formattedYear = `${year} Year`;

  if (date && year) return `${formattedYear} | ${formattedDate}`;
  if (date) return formattedDate;
  if (year) return formattedYear;
  return null;
};

export default function Question({ type, text, answers, ...props }) {
  const size = useContext(ResponsiveContext);
  const chartData = getChartData({ type, answers });
  const desktopWithGraph = size !== 'small' && chartData;
  const filteredAnswers =
    type === 'short_answer' ? answers : answers.filter((answer) => answer.text);

  return (
    <Box size="large" margin="small" css={{ marginBottom: '2rem' }} {...props}>
      <Text css={{ marginBottom: '0.5rem' }} weight="bold" size="large">
        {text}
      </Text>
      <Box gap="small" direction={size === 'small' ? 'column' : 'row'}>
        {chartData && (
          <Box
            background="white"
            pad="small"
            round="4px"
            css={{ width: '100%', height: '20rem' }}
          >
            <div css={{ width: '100%', height: '100%' }}>
              <BarChart data={chartData} />
            </div>
          </Box>
        )}
        <Box
          background="white"
          round="4px"
          css={{
            overflowY: 'auto',
            width: '100%',
            maxHeight: desktopWithGraph ? '20rem' : '10rem',
          }}
        >
          {filteredAnswers.map((answer, i) => {
            const answerString = getAnswerString({ type, answer });
            const metaString = getMetaString(answer);
            return (
              <Box
                key={i}
                pad="small"
                css={{
                  minHeight: 'auto',
                  borderBottom: 'solid 1px #cbcbcb',
                  flexShrink: 0,
                }}
              >
                <Text>{answerString}</Text>
                {metaString && (
                  <Text
                    css={{
                      marginTop: '0.5rem',
                      alignSelf: 'flex-end',
                      color: '#424242',
                    }}
                  >
                    {metaString}
                  </Text>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
