import React, { useContext } from 'react';
import { categories } from '../categories';
import { Box, Text, ResponsiveContext } from 'grommet';
import { navigate } from 'gatsby';

const DesktopContent = ({ college }) => (
  <div
    css={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      maxWidth: '1400px',
    }}
  >
    {Object.values(categories).map((category) => {
      const BackgroundImage = category.image;
      return (
        <Box
          elevation="medium"
          round="xsmall"
          border={{ color: 'brand', size: 'small' }}
          onClick={() =>
            navigate(`/app/${college}/${category.label.toLowerCase()}`)
          }
          css={{ overflow: 'hidden' }}
          margin="2rem"
          key={category.label}
        >
          <BackgroundImage style={{ height: '380px', width: '350px' }}>
            <Box
              background={{ color: 'background', opacity: 'strong' }}
              css={{ width: '100vw', height: '1.75rem' }} //Overflow is hidden by parent container
            >
              <Text alignSelf="center" size="large">
                {category.label}
              </Text>
            </Box>
          </BackgroundImage>
        </Box>
      );
    })}
  </div>
);

const MobileContent = ({ college }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
  >
    {Object.values(categories).map((category) => {
      const BackgroundImage = category.image;
      return (
        <Box
          elevation="medium"
          round="xsmall"
          border={{ color: 'brand', size: 'small' }}
          onClick={() =>
            navigate(`/app/${college}/${category.label.toLowerCase()}`)
          }
          css={{ overflow: 'hidden' }}
          margin="2vh"
          key={category.label}
        >
          <BackgroundImage style={{ height: '190px', width: '330px' }}>
            <Box
              background={{ color: 'background', opacity: 'strong' }}
              css={{ width: '100vw', height: '1.75rem' }} //Overflow is hidden by parent container
            >
              <Text alignSelf="center" size="large">
                {category.label}
              </Text>
            </Box>
          </BackgroundImage>
        </Box>
      );
    })}
  </div>
);

export default function ResponsiveContent({ college }) {
  const size = useContext(ResponsiveContext);
  return size === 'small' ? (
    <MobileContent college={college} />
  ) : (
    <DesktopContent college={college} />
  );
}
