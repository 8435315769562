import React from 'react';
import { Box, Text } from 'grommet';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Logo } from '../components';
import Footer from '../content/layout/Footer';

const BackgroundSection = ({ children, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "auth-background.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <BackgroundImage
      fluid={data.desktop.childImageSharp.fluid}
      css={{
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'auto',
        backgroundPosition: 'top center',
        backgroundSize: 'cover',
        backgroundColor: 'rgba(72, 15, 155, .4)',
        backgroundBlendMode: 'multiply',
      }}
      {...props}
    >
      {children}
    </BackgroundImage>
  );
};

export default function Layout({ children }) {
  return (
    <Box css={{ minHeight: window.innerHeight }}>
      <BackgroundSection>
        <Box
          width="30rem"
          background="brand"
          round="xsmall"
          css={{
            margin: '0.5rem',
            padding: '1.5rem',
          }}
        >
          <Logo css={{ marginBottom: '1rem' }} />
          <Text
            size="0.9em"
            textAlign="center"
            weight="bold"
            css={{ marginBottom: '1rem' }}
          >
            UVA students and alumni answer anonymous questions.
            <br />
            High school students and parents see real answers.
          </Text>
          {children}
        </Box>
      </BackgroundSection>
      <Footer />
    </Box>
  );
}
