import React from 'react';
import { Box, Text } from 'grommet';
import { useParams } from '@reach/router';
import { useUser } from '../../providers';
import { navigate } from 'gatsby';
import { colleges } from '../colleges';
import ResponsiveContent from './ResponsiveContent';

export default function Home() {
  const { college } = useParams();
  const { user, role } = useUser();

  // Waiting for data to be loaded before checking
  if (user === undefined || role === undefined) return null;
  if (user === null) {
    navigate('/app/auth');
    return null;
  }
  if (!user.emailVerified) {
    navigate('/app/auth/verify');
    return null;
  }

  return (
    <Box align="center" css={{ width: '100%' }}>
      <Text
        textAlign="center"
        size="large"
        color="white"
        weight="bold"
        margin={{ top: 'small' }}
      >
        {role === college ? <CollegeHome /> : <HSHome />}
      </Text>
      <ResponsiveContent college={college} />
    </Box>
  );
}

function HSHome() {
  const { college } = useParams();
  return (
    <Text>
      Click on any category to see{' '}
      <Text size="large" weight="bold" color="#ff7418">
        {colleges[college].name}
      </Text>{' '}
      students' answers to your questions!
    </Text>
  );
}

function CollegeHome() {
  const { college } = useParams();
  return (
    <Text>
      Click on any category to{' '}
      <Text size="large" weight="bold" color="#ff7418">
        anonymously
      </Text>{' '}
      answer questions about{' '}
      <Text size="large" weight="bold" color="#ff7418">
        {colleges[college].name}
      </Text>
      ! You can answer as many questions as you want.
    </Text>
  );
}
