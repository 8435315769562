import React from 'react';
import { Select } from 'grommet';
import { useFormContext, Controller } from 'react-hook-form';
import { FormField } from './styles';

// The value returned to RHF is the entire option object, not just the value property
// ex: { value: 'test', label: 'Test' }
const SelectField = ({ name, label, options, ...rest }) => {
  const { control, formState } = useFormContext();
  return (
    <FormField disabled={formState.isSubmitting} label={label}>
      <Controller
        as={Select}
        name={name}
        control={control}
        // The Select component returns data in a strange format
        // this pulls it properly for RHF
        onChange={([data]) => data.value}
        options={options}
        labelKey="label"
        valueKey="value"
        disabled={formState.isSubmitting}
        plain
        {...rest}
      />
    </FormField>
  );
};

export default SelectField;
