import React from 'react';
import { Button } from 'grommet';
import { SEO, Link } from '../components';

export default function EmailVerification() {
  return (
    <div>
      <SEO title="Home" />

      <Link
        to="/app/auth/hs/login"
        css={{
          width: '100%',
          marginRight: '2rem',
          marginLeft: '2rem',
          marginTop: '1rem',
        }}
      >
        <Button
          primary
          color="brand"
          css={{ padding: '0.5rem', textAlign: 'center', width: '100%' }}
        >
          Incoming Students, HS Students, & Parents <br />
          <div css={{ marginTop: '.2rem' }}> </div>
          <b>CLICK HERE</b>
        </Button>
      </Link>
      <Link
        to="/app/auth/uva/login"
        css={{ width: '100%', marginRight: '2rem', marginLeft: '2rem' }}
      >
        <Button
          primary
          color="brand"
          margin={{ top: '8vh' }}
          css={{
            padding: '0.5rem',
            textAlign: 'center',
            width: '100%',
          }}
        >
          Current UVA Students & Alumni <br />
          <div css={{ marginTop: '.2rem' }}> </div>
          <b>CLICK HERE</b>
        </Button>
      </Link>

      <Link css={{ width: '100%', marginRight: '2rem', marginLeft: '2rem' }}>
        <a
          href="https://forms.gle/v3gQ46CRSJcvM7db9"
          target="_blank"
          rel="noreferrer"
          css={{ width: '100%' }}
        >
          <Button
            primary
            color="brand"
            margin={{ top: '8vh' }}
            css={{
              padding: '0.5rem',
              textAlign: 'center',
              width: '100%',
            }}
          >
            View Other Colleges <br />
            <div css={{ marginTop: '.2rem' }}> </div>
            <b>CLICK HERE</b>
          </Button>
        </a>
      </Link>
    </div>
  );
}
