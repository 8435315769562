import React, { useState, useEffect } from 'react';
import { Spinner, SEO } from '../../../components';
import Layout from '../Layout';
import { getCategoryWithAnonymousAnswers } from '../utils';
import Question from './Question';
import { categories } from '../../categories';

export default function HSCategory({ category, college }) {
  const [questions, setQuestions] = useState();

  useEffect(() => {
    // Clearing data on category change
    setQuestions();

    getCategoryWithAnonymousAnswers(category).then((questions) => {
      setQuestions(questions.filter((question) => question.answers.length));
    });
  }, [category, college]);

  return (
    <Layout category={category}>
      <SEO title={categories[category].label} />
      {!questions ? (
        <Spinner />
      ) : (
        questions.map((question) => (
          <Question key={question.id} {...question} />
        ))
      )}
    </Layout>
  );
}
