import React from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { Link, SEO } from '../../components';
import { TextField, SubmitButton, CheckboxField } from '../../components/form';
import { useLogin } from '../utils';
import { useLocation } from '@reach/router';
import { Button, Text } from 'grommet';
import { FormContainer, LinkRow } from '../styles';

export default function Login({ college }) {
  const defaultValues = useLocation().state || {};
  const formMethods = useForm({ defaultValues });
  const { handleSubmit, watch } = formMethods;
  const login = useLogin();

  return (
    <>
      <SEO title="Login" />
      <FormContext {...formMethods}>
        <FormContainer onSubmit={handleSubmit(login)}>
          <TextField
            name="computingID"
            label="UVA Computing ID"
            placeholder="ex. abc7de"
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            placeholder="At least 6 characters"
          />
          <LinkRow>
            <CheckboxField
              name="rememberMe"
              label={<Text size="0.9em"> Keep me signed in </Text>}
            />
            <Link
              to={`/app/auth/${college}/forgot-password`}
              size="small"
              state={watch(['computingID'])}
              css={{ margin: '0rem', textDecoration: 'underline' }}
            >
              Forgot password?
            </Link>
          </LinkRow>
          <Link
            to={`/app/auth/${college}/signup`}
            size="med"
            state={watch(['computingID', 'password'])}
            css={{ width: '100%', margin: '1.2rem 0', marginTop: '1.5rem' }}
          >
            <Button
              primary
              color="brand"
              state={watch(['computingID', 'password'])}
              css={{
                padding: '0.5rem',
                textAlign: 'center',
                width: '100%',
                fontWeight: 'bold',
              }}
            >
              Sign Up
            </Button>
          </Link>
          <SubmitButton
            css={{
              margin: '0',
              marginTop: '.5rem',
              borderColor: '#ff7418',
              backgroundColor: '#ff7418',
              color: '#000000',
              fontWeight: 'bold',
            }}
            label="Log In"
          />
          <Link to={`/app/auth/`} size="med" css={{ marginTop: '1.5rem' }}>
            {' < Back'}
          </Link>
        </FormContainer>
      </FormContext>
    </>
  );
}
