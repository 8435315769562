import React from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { Link, SEO } from '../../components';
import { SelectField, TextField, SubmitButton } from '../../components/form';
import { useSignup } from './utils';
import { useLocation } from '@reach/router';
import { FormContainer, LinkRow } from '../styles';

export default function Signup({ college }) {
  const defaultValues = useLocation().state || {};
  const formMethods = useForm({ defaultValues });
  const { handleSubmit, watch } = formMethods;
  const signup = useSignup();

  return (
    <>
      <SEO title="Signup" />
      <FormContext {...formMethods}>
        <FormContainer onSubmit={handleSubmit(signup)}>
          <TextField
            name="computingID"
            label="UVA Computing ID"
            placeholder="ex. abc7de"
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            placeholder="At least 6 characters"
          />
          <TextField
            name="confirmpassword"
            label="Confirm Password"
            type="password"
          />
          <SelectField
            name="year"
            label="Year"
            options={[
              { value: '1st', label: '1st Year' },
              { value: '2nd', label: '2nd Year' },
              { value: '3rd', label: '3rd Year' },
              { value: '4th', label: '4th Year' },
              { value: 'alumni', label: 'Alumni' },
            ]}
            placeholder="Please select your year"
          />
          <SubmitButton
            css={{ margin: '1rem 0', fontWeight: 'bold' }}
            label="Sign Up"
          />
          <LinkRow>
            <Link
              to={`/app/auth/${college}/login`}
              size="med"
              state={watch(['computingID', 'password'])}
              css={{ marginTop: '1.5rem' }}
            >
              {' < Back'}
            </Link>
            <Link
              to={`/app/auth/${college}/login`}
              size="small"
              state={watch(['computingID', 'password'])}
              css={{ textDecoration: 'underline', textAlign: 'right' }}
            >
              Already have an account? Log in
            </Link>
          </LinkRow>
        </FormContainer>
      </FormContext>
    </>
  );
}
