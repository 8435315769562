import React from 'react';
import { CheckBox } from 'grommet';
import { useFormContext, Controller } from 'react-hook-form';

export default function CheckboxField(props) {
  const { control, formState } = useFormContext();
  return (
    <Controller
      as={CheckBox}
      control={control}
      defaultValue={false}
      disabled={formState.isSubmitting}
      {...props}
    />
  );
}
