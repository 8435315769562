import { useSnackbar } from '../../providers';
import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import { useParams } from '@reach/router';

import {
  ERROR_REQUIRED_FIELDS,
  ERROR_PASSWORD_MATCH,
  ERROR_WEAK_PASSWORD,
  ERROR_EMAIL_IN_USE,
  ERROR_SIGNUP_GENERIC,
  MESSAGE_CREATING_ACCOUNT,
} from '../constants';

// Set of helper functions for auth forms
export const useSignup = () => {
  const setNotification = useSnackbar();
  const { college } = useParams();

  return async ({ computingID, password, confirmpassword, year }) => {
    try {
      if (!(computingID && password && year?.value)) {
        setNotification(ERROR_REQUIRED_FIELDS);
        return;
      }

      if (password !== confirmpassword) {
        setNotification(ERROR_PASSWORD_MATCH);
        return;
      }

      setNotification(MESSAGE_CREATING_ACCOUNT);
      const data = await fetch('/api/signupCollege', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          computingID,
          password,
          year: year?.value,
          college,
        }),
      }).then((res) => res.json());

      if (!data.success) {
        if (data?.errorInfo?.code === 'auth/invalid-password') {
          setNotification(ERROR_WEAK_PASSWORD);
          return;
        } else if (data?.errorInfo?.code === 'auth/email-already-exists') {
          setNotification(ERROR_EMAIL_IN_USE);
          return;
        } else throw data;
      }

      const { user } = await firebase
        .auth()
        .signInWithEmailAndPassword(`${computingID}@virginia.edu`, password);
      await user.sendEmailVerification();

      navigate('/app/verify');
    } catch (e) {
      console.error(e);
      setNotification(ERROR_SIGNUP_GENERIC);
    }
  };
};
