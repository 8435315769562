import firebase from 'firebase/app';
import { useUser, useSnackbar } from '../../providers';

export const getCategoryWithAnonymousAnswers = async (category) => {
  const data = await fetch(
    `/api/anonymousData?college=uva&category=${category}`
  ).then((res) => res.json());
  return data;
};

export const getQuestionData = async ({ category }) => {
  const answerSnap = await firebase
    .firestore()
    .collection('colleges')
    .doc('uva')
    .collection('questions')
    .where('category', '==', category)
    .get();

  // Grabbing question data from Firestore snapshot
  let questions = [];
  answerSnap.forEach((doc) => {
    questions.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return questions;
};

// Fetches single answers for any incoming questions based on a single UID
// Used for grabbing all of a user's previously submitted answers
export const getQuestionsAnswersByUID = ({ questions, uid }) => {
  return Promise.all(
    questions.map((question) => {
      return firebase
        .firestore()
        .collection('colleges')
        .doc('uva')
        .collection('questions')
        .doc(question.id)
        .collection('answers')
        .doc(uid)
        .get()
        .then((answerDoc) => {
          const answer = !answerDoc.exists
            ? null
            : {
                id: answerDoc.id,
                ...answerDoc.data(),
              };
          return { ...question, answer };
        });
    })
  );
};

export const useSubmitAnswer = () => {
  const { user, doc, incrementQuestionsAnswered } = useUser();
  const setNotification = useSnackbar();
  return ({ edit, questionID, answer }) => {
    return firebase
      .firestore()
      .collection('colleges')
      .doc('uva')
      .collection('questions')
      .doc(questionID)
      .collection('answers')
      .doc(user.uid)
      .set({
        ...answer,
        reviewed: false,
        year: doc.year || null,
      })
      .then(() => {
        if (!edit) incrementQuestionsAnswered();
        setNotification(
          `Your answer has been ${edit ? 'updated' : 'submitted'}.`
        );
      })
      .catch((e) => {
        console.error(e);
        setNotification(
          `There was an issue ${edit ? 'updating' : 'submitting'} your answer.`
        );
      });
  };
};
