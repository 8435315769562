import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Text } from 'grommet';

export default function Link({ to, state, ...props }) {
  return (
    <GatsbyLink
      to={to}
      state={state}
      css={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'inherit',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      <Text {...props} />
    </GatsbyLink>
  );
}
