import React, { useState, useContext, useCallback } from 'react';
import {
  Header as GrommetHeader,
  Box,
  Button,
  Text,
  Collapsible,
  ResponsiveContext,
} from 'grommet';
import { Link } from '../../components';
import firebase from 'firebase/app';
import { useUser } from '../../providers';
import { MenuOutline as MenuIcon } from '@styled-icons/evaicons-outline';
import { useMatch } from '@reach/router';

//Copies functionality of a react-router NavLink
const NavLink = ({ children, ...props }) => {
  const match = useMatch(props.to);

  //Found these color values using dev tools on v1
  return (
    <Link {...props} css={{ color: match ? 'white' : 'hsla(0,0%,100%,.5)' }}>
      {children}
    </Link>
  );
};

const Header = ({ college }) => {
  const size = useContext(ResponsiveContext);

  return size === 'small' ? (
    <HeaderMobile college={college} />
  ) : (
    <HeaderDesktop college={college} />
  );
};

const HeaderMobile = ({ college }) => {
  const { user, doc, role } = useUser();
  const [showDrawer, setDrawer] = useState(false);

  const closeDrawer = useCallback(() => {
    setDrawer(false);
  }, []);

  const toggleDrawer = useCallback(() => {
    setDrawer((open) => !open);
  }, []);

  return (
    <GrommetHeader background="brand" pad="medium">
      <Box>
        <Box
          width="100vw"
          direction="row"
          gap="medium"
          justify="between"
          align="center"
        >
          <Link to={`/app/${college}`} weight={600}>
            College Unfiltered @ UVA
          </Link>
          <MenuIcon size={30} onClick={toggleDrawer} />
        </Box>
        <Collapsible open={showDrawer}>
          <Box align="center" css={{ marginTop: '1rem' }} gap="small">
            <NavLink to={`/app/${college}`} onClick={closeDrawer} size="small">
              Home
            </NavLink>
            <NavLink
              to={`/app/${college}/submit`}
              size="small"
              onClick={closeDrawer}
            >
              Submit Questions
            </NavLink>
            <NavLink
              to={`/app/${college}/feedback`}
              size="small"
              onClick={closeDrawer}
            >
              Give Feedback
            </NavLink>
            <Text size="small" css={{ marginTop: '1rem' }}>
              {user.email}
            </Text>
            {/* Only showing questions answered section to non-HS students */}
            {role && (
              <Collapsible direction="horizontal" open={!!doc}>
                <Text size="small" color="brand" truncate>
                  {doc?.questionsAnswered} Question
                  {doc?.questionsAnswered !== 1 && 's'} Answered
                </Text>
              </Collapsible>
            )}
            <Button
              primary
              color="#8A99B3"
              label="Log Out"
              onClick={() => firebase.auth().signOut()}
              size="small"
            />
          </Box>
        </Collapsible>
      </Box>
    </GrommetHeader>
  );
};

const HeaderDesktop = ({ college }) => {
  const { user, doc, role } = useUser();

  return (
    <GrommetHeader background="brand" pad="small">
      <Box direction="row" gap="medium">
        <Link to={`/app/${college}`} weight={600} css={{ marginRight: 20 }}>
          College Unfiltered @ UVA
        </Link>
        <NavLink to={`/app/${college}`} size="small">
          Home
        </NavLink>
        <NavLink to={`/app/${college}/submit`} size="small">
          Submit Questions
        </NavLink>
        <NavLink to={`/app/${college}/feedback`} size="small">
          Give Feedback
        </NavLink>
      </Box>
      <Box direction="row" gap="medium" align="center">
        <Text size="small">{user.email}</Text>
        {/* Only showing questions answered section to non-HS students */}
        {role && (
          <Collapsible direction="horizontal" open={!!doc}>
            <Text size="small" color="brand" truncate>
              {doc?.questionsAnswered} Question
              {doc?.questionsAnswered !== 1 && 's'} Answered
            </Text>
          </Collapsible>
        )}
        <Button
          primary
          color="#8A99B3"
          size="small"
          label="Log Out"
          onClick={() => firebase.auth().signOut()}
        />
      </Box>
    </GrommetHeader>
  );
};

export default Header;
