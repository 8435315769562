import React from 'react';
import { TextInput } from 'grommet';
import { useFormContext, Controller } from 'react-hook-form';
import { FormField } from './styles';
import { useTheme } from 'emotion-theming';

const InputWithFocus = (props) => {
  const theme = useTheme();
  return (
    <TextInput
      css={{
        transition: 'box-shadow 0.1s ease-in-out',
        ':focus': {
          boxShadow: `0 0 1pt 1pt ${theme.global.colors.brand.dark}`,
        },
      }}
      {...props}
    />
  );
};

const TextField = ({ name, label, ...rest }) => {
  const { formState, control } = useFormContext();
  return (
    <FormField disabled={formState.isSubmitting} label={label}>
      <Controller
        as={InputWithFocus}
        control={control}
        name={name}
        defaultValue=""
        disabled={formState.isSubmitting}
        plain
        {...rest}
      />
    </FormField>
  );
};

export default TextField;
