import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { useForm, FormContext } from 'react-hook-form';
import { Box, Text, Button } from 'grommet';
import { SEO, Spinner, Link } from '../../components';
import { TextField, SubmitButton } from '../../components/form';
import { useSnackbar } from '../../providers';

const WARNING_EXPIRED =
  'The verification code used is expired. Please send another password reset email and try again.';
const WARNING_INVALID =
  'The verification code used is invalid. This can happen if the code is malformed, expired, or has already been used.';
const WARNING_GENERIC =
  'An error occured while validating your verification code. Please double check the link used or resend your password reset email.';
const NOTIFICATION_WEAK_PASSWORD = 'Please choose a stronger password.';
const NOTIFICATION_GENERIC = 'An error occured while resetting your password.';
const MESSAGE_SUCCESS = 'Your password has been sucessfully updated.';

const ResetPassword = ({ code }) => {
  const [warning, setWarning] = useState();
  const [showForm, setShowForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const setNotification = useSnackbar();

  useEffect(() => {
    const applyCode = async () => {
      try {
        await firebase.auth().verifyPasswordResetCode(code);
        setShowForm(true);
      } catch (e) {
        switch (e.code) {
          case 'auth/expired-action-code':
            setWarning(WARNING_EXPIRED);
            return;
          case 'auth/invalid-action-code':
            setWarning(WARNING_INVALID);
            return;
          default:
            setWarning(WARNING_GENERIC);
            return;
        }
      }
    };
    applyCode();
  }, [code]);

  const handleSubmit = async ({ password }) => {
    try {
      await firebase.auth().confirmPasswordReset(code, password);
      setSuccess(true);
    } catch (e) {
      switch (e.code) {
        case 'auth/weak-password':
          setNotification(NOTIFICATION_WEAK_PASSWORD);
          return;
        default:
          setNotification(NOTIFICATION_GENERIC);
      }
    }
  };

  return (
    <>
      <SEO title="Password reset" />
      {warning ? (
        <Box margin={{ top: 'small' }}>
          <Text textAlign="center">{warning}</Text>
        </Box>
      ) : success ? (
        <Box margin={{ top: 'small' }}>
          <Text textAlign="center">{MESSAGE_SUCCESS}</Text>
          <Link
            to="/app/auth"
            css={{
              width: '100%',
              marginRight: '2rem',
              marginLeft: '2rem',
              marginTop: '1rem',
            }}
          >
            <Button
              primary
              color="brand"
              css={{ padding: '0.5rem', textAlign: 'center', width: '100%' }}
            >
              Back to login
            </Button>
          </Link>
        </Box>
      ) : showForm ? (
        <PasswordForm onSubmit={handleSubmit} />
      ) : (
        <Spinner />
      )}
    </>
  );
};

const PasswordForm = ({ onSubmit }) => {
  const formMethods = useForm();
  const { handleSubmit } = formMethods;

  return (
    <FormContext {...formMethods}>
      <form
        css={{ display: 'flex', flexDirection: 'column', marginTop: 12 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField name="password" label="New Password" type="password" />
        <SubmitButton css={{ margin: '1rem 0' }} label="Submit" />
      </form>
    </FormContext>
  );
};

export default ResetPassword;
