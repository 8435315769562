import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import { Box, Text } from 'grommet';
import { SEO, Spinner } from '../../components';
import { useUser } from '../../providers';

const WARNING_EXPIRED =
  'The verification code used is expired. Please send another verification email and try again.';
const WARNING_INVALID =
  'The verification code used is invalid. This can happen if the code is malformed, expired, or has already been used.';
const WARNING_GENERIC =
  'An error occured while validating your verification code. Please double check the link used or resend your verification email.';

const VerifyEmail = ({ code }) => {
  const [warning, setWarning] = useState();
  const { user, reloadUser } = useUser();

  useEffect(() => {
    const applyCode = async () => {
      try {
        // Wait for Firebase user to be populated before verifying
        if (user === undefined) return;

        await firebase.auth().applyActionCode(code);

        if (user === null) {
          navigate('/app/auth');
          return;
        }

        await reloadUser();
        navigate('/app/uva');
      } catch (e) {
        console.error(e);
        switch (e.code) {
          case 'auth/expired-action-code':
            setWarning(WARNING_EXPIRED);
            return;
          case 'auth/invalid-action-code':
            setWarning(WARNING_INVALID);
            return;
          default:
            setWarning(WARNING_GENERIC);
        }
      }
    };
    applyCode();
  }, [code, user, reloadUser]);

  return (
    <>
      <SEO title="Verify your email" />
      {!warning ? (
        <Spinner />
      ) : (
        <Box margin={{ top: 'small' }}>
          <Text textAlign="center">{warning}</Text>
        </Box>
      )}
    </>
  );
};

export default VerifyEmail;
