import React from 'react';
import { useForm, FormContext } from 'react-hook-form';
import styled from '@emotion/styled';
import { SEO } from '../../components';
import { TextField, SubmitButton } from '../../components/form';
import { useForgotPassword } from '../utils';
import { useLocation } from '@reach/router';

const FormContainer = styled.form({
  display: 'flex',
  flexDirection: 'column',
});

export default function ForgotPassword() {
  const defaultValues = useLocation().state || {};
  const formMethods = useForm({ defaultValues });
  const { handleSubmit } = formMethods;
  const forgotPassword = useForgotPassword();

  return (
    <>
      <SEO title="Forgot Password" />
      <FormContext {...formMethods}>
        <FormContainer onSubmit={handleSubmit(forgotPassword)}>
          <TextField name="computingID" label="UVA Computing ID" />
          <SubmitButton
            css={{ margin: '1rem 0' }}
            label="Send Recovery Email"
          />
        </FormContainer>
      </FormContext>
    </>
  );
}
