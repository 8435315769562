import React, { useState, useContext } from 'react';
import {
  Text,
  FormField,
  Box,
  TextInput,
  Button,
  RadioButton,
  ResponsiveContext,
} from 'grommet';
import { useTheme } from 'emotion-theming';
import { useSnackbar } from '../../../providers';
import { useSubmitAnswer } from '../utils';
import { WARNING_NO_SELECTION } from './constants';

export default function SingleSelectShortAnswer({
  id,
  text: questionText,
  options,
  answer,
  ...props
}) {
  const [selection, setSelection] = useState(answer?.selection);
  const [isEditing, setEditing] = useState(!!answer);
  const [answerText, setAnswerText] = useState(answer?.text || '');
  const submitAnswer = useSubmitAnswer();
  const setNotification = useSnackbar();
  const size = useContext(ResponsiveContext);

  const theme = useTheme();
  const { disabled: colorDisabled } = theme.global.colors;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selection) {
      setNotification(WARNING_NO_SELECTION);
      return;
    }

    submitAnswer({
      edit: isEditing,
      questionID: id,
      answer: {
        selection,
        text: answerText || null,
        date: new Date(),
      },
    }).then(() => {
      setEditing(true);
    });
  };

  return (
    <Box size="large" margin="small" {...props}>
      <Text css={{ marginBottom: '0.5rem' }} weight="bold" size="large">
        {questionText}
      </Text>
      <Box
        direction={size === 'small' ? 'column' : 'row'}
        gap="medium"
        wrap
        css={{ marginBottom: '0.5rem' }}
      >
        {options.map((option) => (
          <RadioButton
            key={option}
            label={option}
            name={option}
            checked={option === selection}
            onChange={(e) => setSelection(option)}
          />
        ))}
      </Box>
      <form
        css={{ display: 'flex', alignItems: 'center' }}
        onSubmit={handleSubmit}
      >
        <FormField css={{ width: '100%', margin: 0 }}>
          <Box background={!selection ? colorDisabled : 'white'}>
            <TextInput
              disabled={!selection}
              plain
              value={answerText}
              onChange={(e) => setAnswerText(e.target.value)}
              placeholder="Wanna add more? Make a selection, then type here..."
            />
          </Box>
        </FormField>
        <Button
          type="submit"
          label={isEditing ? 'Edit' : 'Submit'}
          primary
          color="#001a63"
          css={{ marginLeft: 12, width: '7rem' }}
          disabled={!selection}
        />
      </form>
    </Box>
  );
}
