import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarChart = ({ data }) => {
  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        backgroundColor: '#001a63',
        data: Object.values(data),
      },
    ],
  };

  return (
    <Bar
      data={chartData}
      options={{
        maintainAspectRatio: false,
        legend: { display: false },
        tooltips: { enabled: false },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 100,
                callback: (value) => {
                  return value + '%';
                },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                color: 'rgba(0, 0, 0, 0)',
              },
            },
          ],
        },
      }}
    />
  );
};

export default BarChart;
