import React, { useState, useContext } from 'react';
import {
  Text,
  FormField,
  Box,
  TextInput,
  Button,
  CheckBoxGroup,
  ResponsiveContext,
} from 'grommet';
import { useTheme } from 'emotion-theming';
import { useSnackbar } from '../../../providers';
import { useSubmitAnswer } from '../utils';
import { WARNING_NO_SELECTION } from './constants';

export default function MultiSelectShortAnswer({
  id,
  text: questionText,
  options,
  answer,
  props,
}) {
  const [selections, setSelections] = useState(answer?.selections || []);
  const [isEditing, setEditing] = useState(!!answer);
  const [answerText, setAnswerText] = useState(answer?.text || '');
  const submitAnswer = useSubmitAnswer();
  const setNotification = useSnackbar();
  const size = useContext(ResponsiveContext);

  const theme = useTheme();
  const { disabled: colorDisabled } = theme.global.colors;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selections.length) {
      setNotification(WARNING_NO_SELECTION);
      return;
    }

    submitAnswer({
      edit: isEditing,
      questionID: id,
      answer: {
        selections,
        text: answerText || null,
        date: new Date(),
      },
    }).then(() => {
      setEditing(true);
    });
  };

  return (
    <Box size="large" margin="small" {...props}>
      <Text css={{ marginBottom: '0.5rem' }} weight="bold" size="large">
        {questionText}
      </Text>
      <CheckBoxGroup
        id="check-box-group-id"
        name="controlled"
        value={selections}
        onChange={({ value: nextValue }) => setSelections(nextValue)}
        options={options}
        direction={size === 'small' ? 'column' : 'row'}
        gap="medium"
        css={{ marginBottom: '0.5rem' }}
      />
      <form
        css={{ display: 'flex', alignItems: 'center' }}
        onSubmit={handleSubmit}
      >
        <FormField css={{ width: '100%', margin: 0 }}>
          <Box background={!selections.length ? colorDisabled : 'white'}>
            <TextInput
              disabled={!selections.length}
              plain
              value={answerText}
              onChange={(e) => setAnswerText(e.target.value)}
              placeholder="Wanna add more? Make a selection, then type here..."
            />
          </Box>
        </FormField>
        <Button
          type="submit"
          label={isEditing ? 'Edit' : 'Submit'}
          primary
          color="#001a63"
          css={{ marginLeft: 12, width: '7rem' }}
          disabled={!selections.length}
        />
      </form>
    </Box>
  );
}
