import React, { useState } from 'react';
import { Text, FormField, Box, TextInput, Button } from 'grommet';
import { useSnackbar } from '../../../providers';
import { useSubmitAnswer } from '../utils';
import { WARNING_NO_ANSWER } from './constants';

export default function ShortAnswer({
  id,
  text: questionText,
  answer,
  ...props
}) {
  const [answerText, setAnswerText] = useState(answer?.text || '');
  const [isEditing, setEditing] = useState(!!answer);
  const submitAnswer = useSubmitAnswer();
  const setNotification = useSnackbar();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!answerText) {
      setNotification(WARNING_NO_ANSWER);
      return;
    }

    submitAnswer({
      edit: isEditing,
      questionID: id,
      answer: {
        text: answerText,
        date: new Date(),
      },
    }).then(() => {
      setEditing(true);
    });
  };

  return (
    <Box size="large" margin="small" {...props}>
      <Text css={{ marginBottom: '0.5rem' }} weight="bold" size="large">
        {questionText}
      </Text>
      <form
        css={{ display: 'flex', alignItems: 'center' }}
        onSubmit={handleSubmit}
      >
        <FormField disabled css={{ width: '100%', margin: 0 }}>
          <Box background="white">
            <TextInput
              plain
              value={answerText}
              onChange={(e) => setAnswerText(e.target.value)}
              placeholder="Type your answer here..."
            />
          </Box>
        </FormField>
        <Button
          type="submit"
          label={isEditing ? 'Edit' : 'Submit'}
          primary
          color="#001a63"
          css={{ marginLeft: 12, width: '7rem' }}
          disabled={!answerText}
        />
      </form>
    </Box>
  );
}
