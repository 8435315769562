import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import BackgroundImage from 'gatsby-background-image';

export default function MajorsImage({ children, ...props }) {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "majors.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <BackgroundImage
      fluid={data.desktop.childImageSharp.fluid}
      css={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
      }}
      {...props}
    >
      {children}
    </BackgroundImage>
  );
}
