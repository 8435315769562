import React from 'react';
import { Box, Text } from 'grommet';
import { SEO } from '../components';
import { navigate } from 'gatsby';
import { useUser } from '../providers';

export default function EmailVerification() {
  const { user } = useUser();
  if (user === null) {
    navigate('/');
    return null;
  }

  return (
    <Box>
      <SEO title="Verify your email" />
      <Text
        textAlign="center"
        css={{ marginTop: '2rem' }}
        margin={{ bottom: 'small' }}
      >
        Thank you for signing up! Check your email for an activation link that
        will allow you to log in to CollegeUnfiltered.com.
        <br />
      </Text>
      <Text
        textAlign="center"
        css={{ marginTop: '2rem' }}
        margin={{ bottom: 'small' }}
      >
        Not finding the email? Make sure to check your spam folder. If you still
        haven't received your activation link, please reach out to us at
        collegeunfiltereduva@gmail.com.
      </Text>
    </Box>
  );
}
