import React from 'react';
import { Box, Text } from 'grommet';
import { useTheme } from 'emotion-theming';

export const FormField = ({ disabled, label, children }) => {
  const theme = useTheme();
  const { disabled: colorDisabled } = theme.global.colors;
  return (
    <Box margin={{ bottom: 'small' }}>
      <Text weight="bold" margin={{ horizontal: 'none', vertical: 'xsmall' }}>
        {label}
      </Text>
      <Box background={disabled ? colorDisabled : 'white'} round="4px">
        {children}
      </Box>
    </Box>
  );
};
