import React from 'react';
import { Text, Button } from 'grommet';
import Layout from './Layout';

export default function Feedback() {
  return (
    <Layout>
      <Text
        size="medium"
        textAlign="center"
        weight="bold"
        css={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        We're always looking to make this experience better for you. Tell us how
        by clicking on the button below.
      </Text>
      <a
        href="https://forms.gle/9xPr28UCZaqhgxeS7"
        target="_blank"
        rel="noreferrer"
        css={{ alignSelf: 'center' }}
      >
        <Button
          primary
          css={{ marginTop: '1rem', fontWeight: 'bold' }}
          label="Give Feedback"
        />
      </a>
    </Layout>
  );
}
