import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { Box, Text } from 'grommet';

import VerifyEmail from './VerifyEmail';
import ResetPassword from './ResetPassword';

const ActionCode = () => {
  const location = useLocation();
  const [params, setParams] = useState();

  useEffect(() => {
    setParams(new URLSearchParams(location.search));
  }, [location]);

  if (!params) return <React.Fragment />;

  const mode = params.get('mode');
  const code = params.get('oobCode');
  switch (mode) {
    case 'verifyEmail':
      return <VerifyEmail code={code} />;
    case 'resetPassword':
      return <ResetPassword code={code} />;
    default:
      return (
        <Box margin={{ top: 'small' }}>
          <Text textAlign="center">
            The verification link used has an invalid mode. Please double check
            that you've entered the link correctly.
          </Text>
        </Box>
      );
  }
};

export default ActionCode;
